/* 06.02. Home Two Intro CSS */

.intro-content-two {
  max-width: 562px;
  // margin-top: 100px;

  & .title {
    font-size: 54px;
    line-height: 1;
    color: var(--clr-white);

    & > div {
      display: inline-block;
    }
  }

  & .desc {
    max-width: 700px;
    margin: 0 auto;

    & p {
      font-size: 26px;
      font-weight: 500;
      line-height: 1.67;
      color: var(--clr-white);
    }
  }

  & .btn {
    margin: 0 5px;
    margin-top: 60px;
  }

  @media #{$tablet-device,
    $large-mobile
    } {
    & .title {
      font-size: 36px;
      margin-bottom: 20px;
    }

    & .desc {
      & p {
        font-size: 16px;
      }
    }
    & .btn {
      margin-top: 30px;
    }
  }

  @media #{$extra-small-mobile} {
    & .title {
      font-size: 30px;
    }

    & .desc {
      & p {
        font-size: 14px;
      }
    }
  }
}

.ah-headline.rotate-3 i {
  font-style: normal !important;
}

// SIVERT FIKSES
@media only screen and (max-width: 1200px) {
  .intro-content-two {
    max-width: 670px;
    margin-top: 100px;
    margin-left: 0px;
    & .title {
      font-size: 58px;
      line-height: 1.2;
      color: var(--clr-white);
    }
  }
}

@media only screen and (max-width: 990px) {
  .intro-content-two {
    max-width: 580px;
    margin-top: 140px;
    margin-left: 0px;
    & .title {
      font-size: 52px;
      line-height: 1.2;
      color: var(--clr-white);
    }
  }
}
@media only screen and (max-width: 767px) {
  .intro-content-two {
    max-width: 520px;
    margin-top: 140px;
    margin-left: 0px;
    & .title {
      font-size: 48px;
      line-height: 1.2;
      color: var(--clr-white);
    }
  }
}

@media only screen and (max-width: 575px) {
  .intro-content-two {
    width: 100%;
    margin-top: 70px;
    margin-left: 0px;
    & .title {
      font-size: 35px;
      line-height: 1.2;
      color: var(--clr-white);
    }
  }
}

@media only screen and (max-width: 410px) {
  .intro-content-two {
    width: 100%;
    margin-top: 70px;
    margin-left: 0px;
    & .title {
      font-size: 30px;
      line-height: 1.2;
      color: var(--clr-white);
    }
  }
}
