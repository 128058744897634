/*----------------------------------------*/
/*  11. Widget CSS
/*----------------------------------------*/

.sidebar-widget-wrapper {
  float: left;
  width: 100%;
  padding-left: 30px;

  @media #{$desktop-device,
    $tablet-device,
    $large-mobile
    } {
    padding-left: 0;
  }
}

.sidebar-widget {
  float: left;
  width: 100%;

  & + .sidebar-widget {
    margin-top: 45px;
  }
}

.sidebar-widget-title {
  font-size: 20px;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 30px;
}

.sidebar-widget-search {
  & form {
    position: relative;

    overflow: hidden;

    border-radius: 4px;

    & input {
      padding-right: 50px;
    }

    & button {
      position: absolute;
      top: 0;
      right: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 56px;
      height: 56px;

      color: var(--clr-primary);
      border: none;
      border-radius: 4px;
      background-color: transparent;

      &:hover {
        color: var(--clr-white);
        background-color: var(--clr-primary);
      }
    }
  }
}

.sidebar-widget-cate-list {
  margin: 0;
  padding: 0;

  list-style: none;

  & li {
    & + li {
      border-top: 1px solid #eeeeee;
    }

    & a {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.78;

      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 16px 0 15px 0px;
    }
  }
}

.sidebar-widget-list-post {
  margin: 0;
  padding: 0;

  list-style: none;

  & li {
    padding: 16px 0 15px 0px;
    & + li {
      border-top: 1px solid #eeeeee;
    }

    & a {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: var(--clr-heading);

      position: relative;

      display: block;
      // Responsive
      @media #{$extra-small-mobile} {
        font-size: 16px;
        line-height: 22px;
      }
      &:hover {
        color: var(--clr-primary);
      }
    }
  }
}

.tagcloud {
  margin: -5px;

  & a {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 22px;
    font-family: var(--ff-link);

    display: block;
    float: left;
    overflow: hidden;

    margin: 5px;
    padding: 7px 8px 6px;

    color: var(--clr-primary);
    border: 0;
    border-radius: 3px;
    background: #f5f5f5;

    &:hover {
      color: var(--clr-white);
      background-color: var(--clr-primary);
    }
  }
}

.sidebar-widget-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  z-index: 9;
  padding: 90px 35px;
  text-align: center;
  border-radius: 5px;
  .title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 35px;
    color: var(--clr-white);
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: var(--clr-white);
    margin-bottom: 35px;
  }
  a {
    color: var(--clr-white);
    &:hover {
      color: var(--clr-primary);
    }
  }
}

.footer-widget {
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-widget-title {
  font-size: 18px;
  color: var(--clr-white);
  margin-bottom: 17px;
}

.footer-widget-content {
  font-size: 18px;
  font-weight: 500;
  color: rgb(235, 235, 235);

  & p {
    margin-bottom: 5px;
    color: var(--clr-gray-100);
    font-family: var(--ff-link);

    &:last-child {
      margin-bottom: 0;
    }
  }

  & ul {
    margin-right: -20px;
    margin-bottom: -5px;
    padding-left: 0;

    list-style: none;

    & li {
      margin-bottom: 5px;
      padding-right: 20px;
      a {
        font-size: 16px;
        line-height: 30px;
        // Responsive
        @media #{$desktop-device} {
          font-size: 15px;
        }
        @media #{$extra-small-mobile} {
          font-size: 15px;
        }
      }
    }
  }
}
.ft-badge {
  background-color: var(--clr-primary);
  display: inline-block;
  border-radius: 2px;
  padding: 4px 8px 3px;
  color: var(--clr-white);
  font-size: 10px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 0 7px;
}

.footer-social-inline {
  display: flex;
  flex-wrap: wrap;

  margin-top: 40px;
  margin-right: -10px;
  margin-bottom: -10px;

  & a {
    margin-right: 10px;
    margin-bottom: 10px;

    color: var(--clr-gray-100);

    & i {
      font-size: 24px;
      line-height: 1;

      display: block;
    }

    &:hover {
      color: var(--clr-primary);
    }
  }
}
