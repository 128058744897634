/* 12.03 Success CSS */

.success-section-padding-bottom-180 {
  padding-bottom: 180px;

  // Responsive
  @media #{$laptop-device} {
    padding-bottom: 140px;
  }
  @media #{$desktop-device} {
    padding-bottom: 140px;
  }
  @media #{$tablet-device} {
    padding-bottom: 50px;
  }

  @media #{$large-mobile} {
    padding-bottom: 30px;
  }
}

.success-image-wrap {
  position: relative;
  z-index: 9;
  height: 100%;
  // Responsive
  @media #{$tablet-device} {
    margin-top: 50px;
  }
  @media #{$large-mobile} {
    margin-top: 50px;
  }
  .success-image {
    position: relative;
    top: 0;
    left: 0;
    float: left;
    z-index: 9;
    // Responsive
    @media #{$extra-small-mobile} {
      width: 250px;
    }
    &:nth-child(2) {
      position: absolute;
      top: 70px;
      left: auto;
      right: 0;
      z-index: 8;
      float: right;
      text-align: right;
    }
    img {
      border-radius: 5px;
      box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05),
        0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
      transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
    }
    &:hover {
      & img {
        box-shadow: 0 0.5rem 1.2rem rgba(0, 0, 0, 0.1),
          0 2rem 3rem rgba(0, 0, 0, 0.15);
      }
    }
  }

  & .shape {
    position: absolute;
    z-index: 1;

    &-1 {
      left: -40px;
      bottom: -60px;
      z-index: -1;
    }
  }

  // Responsive
  @media #{$tablet-device} {
    & .shape {
      &-1 {
        left: 0px;
        bottom: -30px;
        z-index: -1;
      }
    }
  }
  @media #{$large-mobile} {
    & .shape {
      &-1 {
        left: 0px;
        bottom: -20px;
        z-index: -1;
      }
    }
  }
}
