/*----------------------------------------*/
/*  12. Footer CSS
/*----------------------------------------*/

.footer-bg-color {
  background-color: #03464a;
}

.footer-section {
  padding: 100px 0 0px;
  overflow: hidden;
  // Responsive
  @media #{$tablet-device} {
    padding: 80px 0 0px;
  }
  @media #{$large-mobile} {
    padding: 60px 0 0px;
  }
}

.copyright {
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  padding: 20px 15px;
  border-top: 1px solid var(--clr-gray-200);
  color: var(--clr-white);
  margin-bottom: 0;
}
