/* 07.01. Page Title CSS */

.page-title-section {
  position: relative;
  height: 800px;
  display: flex;
  align-items: center;
  z-index: 9;
  flex-direction: column;
  justify-content: center;
  object-fit: cover !important;
  // Responsive
  @media #{$laptop-device} {
    height: 600px;
  }
  @media #{$desktop-device} {
    height: 600px;
  }
  @media #{$tablet-device} {
    height: 600px;
  }
  @media #{$large-mobile} {
    height: 450px;
  }
}

.page-title {
  & .title {
    font-size: 46px;
    line-height: 1.2;
    color: var(--clr-white);
    margin: 0;
    text-align: center;
  }

  @media #{$desktop-device} {
    & .title {
      font-size: 42px;
    }
  }

  @media #{$tablet-device} {
    & .title {
      font-size: 34px;
    }
  }

  @media #{$large-mobile} {
    & .title {
      font-size: 23px;
      line-height: 1.5;
    }
  }
}
