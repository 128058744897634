/* 04.7 Funfact CSS */
.funfact {
    text-align: center;
    & .number {
        font-size: 52px;
        font-weight: 400;
        line-height: 1;
        display: block;
        color: var(--clr-primary);
        margin-bottom: 25px;
    }

    & .text {
        font-size: 20px;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 0;
        color: var(--clr-heading);
    }
}