/* 04.04 Button CSS */

.btn {
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 52px;
  padding: 0 40px;

  cursor: pointer;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  outline: none;
  font-family: var(--ff-link);
  // Responsive
  @media #{$large-mobile} {
    font-size: 14px;
    line-height: 35px;
    height: 40px;
    padding: 0 20px;
  }
  &:focus {
    box-shadow: none !important;
  }

  &-icon {
    width: 56px;
    padding: 15px;
  }

  &.disabled {
    opacity: 0.65;
  }
  &:disabled {
    opacity: 0.65;
    background: #002324 !important;
    border-color: #002324 !important;
  }
  &-primary {
    background: #00464a;
    border-color: #00464a;
    color: white;
    &:hover {
      background: #65977e;
      border-color: #65977e;
      //   color: rgb(38, 38, 38);
      transition: all ease-in-out 0.3s;
    }
  }
  &-light {
    --bs-btn-bg: var(--clr-white);
    --bs-btn-border-color: var(--clr-white);
    --bs-btn-color: var(--clr-primary);
  }
  &-register {
    background: #00464a;
    border-color: #00464a;
    color: white;
    padding: 8px 30px;
    &:hover {
      background: #65977e;
      border-color: #65977e !important;
      color: white;
      transition: all ease-in-out 0.3s;
    }
  }
  &-loggin {
    background: none;
    border: none;
    color: white;
    padding: 8px 30px;
    &:hover {
      background: none;
      border-color: #65977e !important;
      color: #bedfd3 !important;
      transition: all ease-in-out 0.3s;
    }
  }

  &-hover {
    &-secondary {
      --bs-btn-hover-bg: var(--clr-black);
      --bs-btn-hover-border-color: transparent;
      --bs-btn-hover-color: var(--clr-white);
    }
    // &-primary {
    //   --bs-btn-hover-bg: var(--clr-primary);
    //   --bs-btn-hover-border-color: transparent;
    //   --bs-btn-hover-color: var(--clr-white);
    // }
  }
  &-outline {
    &-white {
      --bs-btn-bg: transparent;
      --bs-btn-border-color: var(--clr-white);
      --bs-btn-color: var(--clr-white);
    }
  }

  &--small {
    line-height: 40px;
    height: 40px;
    padding: 0 20px;
  }
}

.link {
  font-size: 16px;
  font-weight: 400;
  line-height: 1;

  &-lg {
    font-size: 18px;
  }
  & mark {
    font-weight: 400;
    line-height: 1;

    position: relative;

    padding: 3px 0;

    color: black;
    background-color: transparent;

    &::before,
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 1px;

      content: "";
      transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
      transform: scaleX(1) translateZ(0);
      transform-origin: right;

      background: #e0e0e0;
    }

    &::after {
      transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transform: scaleX(0) translateZ(0);
      transform-origin: left;

      background-color: var(--clr-primary);
    }
  }

  &:hover {
    color: var(--clr-secondary-light);

    & mark {
      &::before {
        transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        transform: scaleX(0) translateZ(0);
      }

      &::after {
        transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
        transform: scaleX(1) translateZ(0);
      }
    }
  }
}
.btn-hover-primary {
  &:hover {
    color: white;
    border-color: rgba(255, 255, 255, 0.5);
    transition: all ease-in-out 0.3s;
  }
}
