.background-registrer {
  background: #03464a;
}
.background-registrer h1 {
  color: #000;
  padding-top: 20px;
}
.passord-ikon:hover {
  cursor: pointer;
}
