/* 05.04 Header Logo CSS */

.header-logo {
  & a {
    position: relative;

    max-width: 140px;

    & img {
      width: 100%;
      padding: 15px 0;

      &.light-logo {
        position: absolute;
        top: 0;
        left: 0;

        opacity: 0;
      }

      &.dark-logo {
      }
    }
  }

  @media #{$extra-small-mobile} {
    & a {
      max-width: 120px;
    }
  }
}

.header-light {
  & .header-logo {
    & a {
      & img {
        &.light-logo {
          opacity: 1;
        }

        &.dark-logo {
          opacity: 0;
        }
      }
    }
  }
}

.svart-til-hvit {
  filter: invert(1) brightness(100);
}

@media screen and (max-width: 575px) {
  .header-logo a img {
    height: 100px !important;
    width: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .header-logo {
    & a {
      position: relative;
      max-width: 110px;
    }
  }
}
