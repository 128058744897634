/* 07.02. Section Title CSS */

.section-title {
  position: relative;
  z-index: 99;
  margin-bottom: 40px;
  // Responsive
  @media #{$large-mobile} {
    margin-bottom: 30px;
  }
  
  & .title {
    font-size: 56px;
    line-height: 1;
    font-weight: 900;
    margin: 0;
    color: var(--clr-heading);
    &.fz-34 {
      font-size: 34px;
      max-width: 690px;
      margin: auto;
    }
    &.fz-32 {
      font-size: 32px;
      line-height: 44px;
      // Responsive
      @media #{$tablet-device} {
        font-size: 26px;
        line-height: 32px;
      }
      @media #{$large-mobile} {
        font-size: 24px;
        line-height: 30px;
        br {
          display: none;
        }
      }
    }
    &.fz-28 {
      font-size: 28px;
      line-height: 44px;
      // Responsive
      @media #{$tablet-device} {
        font-size: 26px;
        line-height: 32px;
      }
      @media #{$large-mobile} {
        font-size: 24px;
        line-height: 30px;
        br {
          display: none;
        }
      }
    }
  }
  & .sub-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    display: block;
    width: 100%;
    margin: 20px 0 0;

    &.fz-65 {
      .title {
        font-size: 70px;
        line-height: 1;
      }
    }

    color: #333;
    &.fz-18 {
      font-size: 18px;
    }
  }
  &.color-light {
    .title {
      color: var(--clr-white);
    }
    .sub-title {
      color: var(--clr-white);
    }
  }

  @media #{$tablet-device} {
    & .title {
      font-size: 38px;
    }
  }
  @media #{$large-mobile} {
    & .title {
      font-size: 36px;
    }
    .sub-title {
      font-size: 18px;
    }
  }
  @media #{$extra-small-mobile} {
    & .title {
      font-size: 30px;
      &.fz-34 {
        font-size: 26px;
      }
      &.fz-32 {
        font-size: 26px;
      }
    }
    .sub-title {
      font-size: 16px;
    }
  }
}

.section-title-two {
  position: relative;
  z-index: 99;
  margin-bottom: 30px;
  // Responsive
  @media #{$desktop-device} {
    margin-bottom: 30px;
  }
  @media #{$large-mobile} {
    margin-bottom: 30px;
  }
  &.color-light {
    & .sub-title {
      color: var(--clr-white-light);
    }
    & .title {
      color: var(--clr-white);
    }
  }

  & .sub-title {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
    color: black;
    position: relative;
    display: block;
    padding-left: 85px;
    margin-bottom: 25px;
    &::before {
      position: absolute;
      content: "";
      width: 70px;
      height: 2px;
      background-color: #6c6c6c;
      left: 0;
      bottom: 0;
    }
  }

  & .title {
    font-size: 54px;
    line-height: 1;
    margin: 0;
    color: var(--clr-heading);
  }

  // Responsive
  @media #{$desktop-device} {
    & .sub-title {
      font-size: 18px;
      margin-bottom: 20px;
    }
    & .title {
      font-size: 30px;
    }
  }
  @media #{$tablet-device} {
    & .sub-title {
      font-size: 18px;
      margin-bottom: 20px;
    }
    & .title {
      font-size: 38px;
    }
  }
  @media #{$large-mobile} {
    & .sub-title {
      font-size: 16px;
      margin-bottom: 10px;
    }
    & .title {
      font-size: 28px;
      line-height: 36px;
    }
  }
  @media #{$extra-small-mobile} {
    & .sub-title {
      font-size: 16px;
      padding-left: 65px;
      &::before {
        width: 60px;
      }
    }
    & .title {
      font-size: 26px;
      line-height: 36px;
      br {
        display: none;
      }
    }
  }
}

.padding-lfr-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.om-oss-lh h2 {
  line-height: .7 !important;
}
.reklamefilmer-lh h2 {
  line-height: .9 !important;
}
.om-oss-h3 h3 {
  font-size: 65px !important;
  line-height: .9 !important;
  padding-top: 118px;
  font-weight: 900;
}

@media screen and (max-width: 1200px) {
  .om-oss-h3 h3 {
    font-size: 50px !important;
    padding-top: 0px !important;
  }
}

@media screen and (max-width: 576px) {
  .om-oss-h3 h3 {
    font-size: 35px !important;
  }
}

@media screen and (max-width: 1200px) {
  .om-sek1 p {
    margin-top: 115px !important;
    
  }
}


@media screen and (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
}